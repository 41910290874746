<template>
  <div class="container">
    <div class="box">
      <div class="logo">
        <img class="logo-img" src="@/assets/logo-light.png" alt="" />
      </div>
      <div class="title">Forgot password?</div>
      <div class="sub-title">
        Enter the email address associated<br />with your account.
      </div>

      <div class="form">
        <Form ref="form" :model="form" :rules="rules">
          <FormItem prop="email">
            <Input
              class="input"
              placeholder="Enter your email"
              size="large"
              type="text"
              v-model="form.email"
            />
          </FormItem>

          <FormItem prop="captcha" style="postion:relative">
            <Input
              class="input"
              placeholder="Enter code"
              size="large"
              type="text"
              maxlength="4"
              v-model="form.captcha"
              style="width:120px"
            />

            <div class="codeWrap">
              <img
                :src="imgCode"
                v-if="imgCode"
                class="imgCode"
                @click="loadCaptcha"
              />
              <p v-else class="coding"><spin></spin></p>
            </div>
          </FormItem>

          <FormItem>
            <Button
              size="large"
              type="primary"
              long
              :loading="loading"
              @click="handleSubmit('form')"
              >Reset Password</Button
            >
          </FormItem>
        </Form>
      </div>

      <router-link to="/user-action/login" class="tip">
        Return to login
      </router-link>
    </div>
  </div>
</template>

<script>
import api from "@/api";
const { _forgetPassword, _captcha } = api;

export default {
  data() {
    return {
      loading: false,
      imgCode: "",
      form: {
        email: "",
        captcha: "",
        captcha_key: ""
      },
      rules: {
        email: [
          {
            required: true,
            message: "Please fill"
          },
          { type: "email", message: "Incorrect email format" }
        ],
        captcha: [
          {
            required: true,
            message: "Please fill"
          }
        ]
      }
    };
  },
  created() {
    this.loadCaptcha();
  },
  methods: {
    loadCaptcha() {
      this.imgCode = "";
      _captcha().then(res => {
        this.imgCode = res.data.captcha_image_content;
        this.form.captcha_key = res.data.captcha_key;
      });
    },
    handleSubmit(name) {
      this.$refs[name].validate(valid => {
        if (valid) {
          this.loading = true;
          _forgetPassword({
            ...this.form
          })
            .then(() => {
              this.$Modal.success({
                title: "Verify Account",
                content:
                  "We have sent an email to your mailbox, please click the link in the email to verify your account"
              });
              // this.$Message.success("success");
            })
            .catch(({ message }) => {
              this.$Message.error(message);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  background: #404040;

  .codeWrap {
    position: absolute;
    left: 131px;
    top: 0;
    width: 150px;
    height: 40px;
    background-color: #f8f8f8;
    overflow: hidden;
    border-radius: 4px;
    .imgCode {
      cursor: pointer;
    }
    .coding {
      position: absolute;
      left: 70px;
      top: 10px;
    }
  }

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 360px;
    padding: 40px;
    background: #333;
    border-radius: 10px;
    color: #fff;
    .logo {
      width: 120px;
      border-radius: 6px;
      margin-bottom: 20px;
      .logo-img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 4px;
    }
    .sub-title {
      font-size: 14px;
      margin-bottom: 20px;
      text-align: center;
    }
    .form {
      width: 100%;
      .input {
        // background: red;
        /deep/ input {
          background: rgba(153, 153, 153, 0.28);
          border-color: transparent;
          color: #fff;
        }
      }
    }
    .tip {
      font-size: 14px;
      margin-bottom: 4px;
      color: @primary-color;
    }
  }
}
</style>
